import styled from 'styled-components';
import { colors } from '../../../styles/vars/colors.style';
import { transitions } from '../../../styles/vars/transitions.style';

/*
 ** If current animatic hasn't changed since Friday (26.02.21)
 **
 ** Image build on:
 ** - Mask in solid colors.pastelGreen400 background with easings.image.colourIn
 ** - Translate in image (delay prop has +0.1 added to it) so image translating always begins 0.1s after the colour mask
 ** - Mask and image transition have 0.95s durations
 **
 ** - Scale image from 110%  to 100%, uses the same +.1s delay as the image translating in
 ** - Scale has duration of 1.99s
 **
 ** Carl broke the build ons down in this file https://docs.google.com/document/d/1H1yq-JahwYaolKvMYUVWkcPrDsVmLtKdsIRPYzQsQnc/edit
 */

export const ImageColorMask = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  ${transitions.ImageColorMaskTransition.buildOn}
  transition-delay: ${({ delay }) => `${delay}s`};
`;

export const ImageColorMaskInner = styled.div`
  background: ${({ transparent }) =>
    transparent ? `transparent;` : `${colors.pastelGreen400};`};
  border-radius: 0.5rem;
  overflow: hidden;
  ${transitions.ImageColorTransition.buildOn}
  transition-delay: ${({ delay }) => `${delay}s`};
`;

export const ImageScale = styled.div`
  ${transitions.ImageScaleTransition.buildOn}
  transition-delay: ${({ delay }) => `${delay}s`};

  > div {
    transition-delay: ${({ delay }) => `${delay}s`};
  }
`;
