import React from 'react';
import PropTypes from 'prop-types';
import { ImageColorMask, ImageColorMaskInner, ImageScale } from './index.style';

// ANIMATION TURNED OFF

const AnimateImageIn = ({ delay, children, transparent }) => {
  return <>{children}</>;

  return (
    <ImageColorMask delay={delay}>
      <ImageColorMaskInner delay={delay} transparent={transparent}>
        <ImageScale delay={delay + 0.1}>{children}</ImageScale>
      </ImageColorMaskInner>
    </ImageColorMask>
  );
};

AnimateImageIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
};

AnimateImageIn.defaultProps = {
  delay: 0,
};

export default AnimateImageIn;
