import { easings } from './easings.style';

export const transitions = {
  ImageColorMaskTransition: {
    buildOn: `
      transform: translateY(101%);
      will-change: transform;
      transition: transform 0.95s ${easings.image.colourIn};

      [data-inview='true'] & {
        transform: translateY(0);
      }`,
  },

  ImageColorTransition: {
    buildOn: `
      transform: translateY(-101%);
      will-change: transform;
      transition: transform 0.95s ${easings.image.colourIn};

      [data-inview='true'] & {
        transform: translateY(0);
      }`,
  },
  //delay should be .1s after inital delay
  ImageScaleTransition: {
    buildOn: `
      transform: translateY(101%);
      will-change: transform;
      transition: transform 0.95s ${easings.image.colourIn};

      > div {
        transform: scale(1.1);
        will-change: transform;
        transition: transform 1.99s ${easings.image.scale};
      }

      [data-inview='true'] & {
        transform: translateY(0);
        > div {
          transform: scale(1);
        }
      }`,
  },
};
